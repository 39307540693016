import { Button } from '@mui/material'
import React from 'react'
import WaveSurfer from 'wavesurfer.js'
import PlayCircleOutlineIconRounded from '@mui/icons-material/PlayCircleOutlineRounded';
import PauseCircleOutlineIconRounded from '@mui/icons-material/PauseCircleOutlineRounded';
const useWavesurfer = (containerRef, options) => {
    const [wavesurfer, setWavesurfer] = React.useState(null)
    
    // Initialize wavesurfer when the container mounts
    // or any of the props change
    React.useEffect(() => {
      if (!containerRef.current) return
  
      const ws = WaveSurfer.create({
        ...options,
        container: containerRef.current,
        preversePitch: true,
        barGap: 5,
      })

      setWavesurfer(ws)
  
      return () => {
        ws.destroy()
      }
    }, [])
  
    return wavesurfer
  }
export const WaveSurferPlayer = (props) => {
    const containerRef = React.useRef()
    const [isPlaying, setIsPlaying] = React.useState(false)
    const [currentTime, setCurrentTime] = React.useState(0)
    const wavesurfer = useWavesurfer(containerRef, props)
    console.log(props)
    // On play button click
    const onPlayClick = React.useCallback(() => {
      wavesurfer.isPlaying() ? wavesurfer.pause() : wavesurfer.play()
    }, [wavesurfer])
    const onRateChange2 = React.useCallback(() => {
        wavesurfer.setPlaybackRate(2)
      }, [wavesurfer])
      const onRateChange15 = React.useCallback(() => {
        wavesurfer.setPlaybackRate(1.5)
      }, [wavesurfer])
      const onRateChange12 = React.useCallback(() => {
        wavesurfer.setPlaybackRate(1.2)
      }, [wavesurfer])
      const onRateChange1 = React.useCallback(() => {
        wavesurfer.setPlaybackRate(1)
      }, [wavesurfer])
      const onRateChange05 = React.useCallback(() => {
        wavesurfer.setPlaybackRate(0.5)
      }, [wavesurfer])
    // Initialize wavesurfer when the container mounts
    // or any of the props change
    React.useEffect(() => {
      if (!wavesurfer) return
  
      setCurrentTime(0)
      setIsPlaying(false)
  
      const subscriptions = [
        wavesurfer.on('play', () => setIsPlaying(true)),
        wavesurfer.on('pause', () => setIsPlaying(false)),
        wavesurfer.on('hover', () => console.log(currentTime)),
        wavesurfer.on('timeupdate', (currentTime) => setCurrentTime(currentTime)),
        wavesurfer.once('decode', () => {
          }),
          wavesurfer.on('mouseenter', (e) => {
            wavesurfer.params.progressColor = 'red';
            wavesurfer.drawBuffer();
          })
      ]

      return () => {
        subscriptions.forEach((unsub) => unsub())
      }
    }, [wavesurfer])
    return (
      <div style={{display:'flex' , alignItems:'center',justifyContent:'center' , flexDirection:'column'}}>
        <div id='waveform' ref={containerRef} style={{ minHeight: '110px' , width:'1100px', }} />
        <div style={{display:"flex",flexDirection:"row-reverse", alignItems:"start", justifyContent:"space-between", fontWeight:"bold" , marginTop:'20px', width:'100%'}}>
                        <a href={props.url} download className='btn btn-label-primary' style={{width:150, height:40, fontWeight:"bold",}}>
                           <Button variant="outlined" style={{color:props.isDarkMode?'rgb(255, 193, 172)':'rgb(119, 237, 139)', borderColor:props.isDarkMode?'rgb(255, 193, 172)':'rgb(119, 237, 139)', fontFamily:'"Public Sans", sans-serif', width:150, height:50, zIndex:100
                                }}>Download</Button>
                        </a>
                            <Button onClick={onPlayClick} variant="outlined"  
                            style={{color:props.isDarkMode?'rgb(255, 193, 172)':'rgb(119, 237, 139)', borderColor:props.isDarkMode?'rgb(255, 193, 172)':'rgb(119, 237, 139)', fontFamily:'"Public Sans", sans-serif', width:50, height:50, zIndex:100
                                }}>
                                    {
                                    !isPlaying?
                                    <PlayCircleOutlineIconRounded sx={{width:30,height:30}}></PlayCircleOutlineIconRounded>
                                    :
                                    <PauseCircleOutlineIconRounded sx={{width:30,height:30}}></PauseCircleOutlineIconRounded>
                                    }
                            </Button>
                            <div style={{display:"flex",flexDirection:"column",gap:3,  fontWeight:"bold",}}>
                              <div style={{display:'flex',flexDirection:'row',gap:3}}>
                                <Button onClick={onRateChange05} variant="outlined"  style={{color:props.isDarkMode?'rgb(255, 193, 172)':'rgb(119, 237, 139)', borderColor:props.isDarkMode?'rgb(255, 193, 172)':'rgb(119, 237, 139)', fontFamily:'"Public Sans", sans-serif', width:50, height:50 ,zIndex:100
                                }}>
                                0.5x
                                </Button>
                            <Button onClick={onRateChange1} variant="outlined"  style={{color:props.isDarkMode?'rgb(255, 193, 172)':'rgb(119, 237, 139)',  borderColor:props.isDarkMode?'rgb(255, 193, 172)':'rgb(119, 237, 139)', fontFamily:'"Public Sans", sans-serif',width:50, height:50,zIndex:100
                                }}> 1x
                            </Button>
                            </div>
                              <div style={{display:'flex',gap:3,flexDirection:'row'}}>
                              <Button onClick={onRateChange15} variant="outlined"  style={{color:props.isDarkMode?'rgb(255, 193, 172)':'rgb(119, 237, 139)', borderColor:props.isDarkMode?'rgb(255, 193, 172)':'rgb(119, 237, 139)', fontFamily:'"Public Sans", sans-serif', width:50, height:50,zIndex:100
                                }}> 1.5x
                              </Button>
                              <Button onClick={onRateChange2} variant="outlined"  style={{color:props.isDarkMode?'rgb(255, 193, 172)':'rgb(119, 237, 139)', borderColor:props.isDarkMode?'rgb(255, 193, 172)':'rgb(119, 237, 139)', fontFamily:'"Public Sans", sans-serif', width:50, height:50,zIndex:100
                                  }}> 2x
                              </Button>
                              </div>
                            
                            
                            </div>
                            
                            
        </div>
                        <div id="waveform-timeline"></div>
      </div>
    )
  }